@use '../../assets/styles/mixins' as *;

.home {
  .home-hero {
    position: absolute;
    margin-bottom: rem(60px);
    width: 100%;
    height: rem(740px);
    overflow-x: hidden;
    transition: width 0.3s var(--easing), margin 0.3s var(--easing);
    z-index: 1;

    @include bp(medium) {
      // height: rem(862px);
      height: 100vh;
      background-image: url('../../assets/static/home-hero-bg.jpeg');
      background-repeat: no-repeat;
      background-size: cover;
    }

    .hero-image {
      object-fit: cover;
      width: 100vw;
      height: rem(740px);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .hero-video {
      @extend .hero-image;

      // height: rem(862px);
      height: 100vh;
    }

    .home-container {
      max-width: var(--container-width);
      margin: 0 auto;
      width: 100%;
      position: relative;
    }

    .h2 {
      max-width: 80%;
      color: var(--color-white);
      z-index: 2;
      position: absolute;
      top: 10rem;
      left: 1rem;
      transition: left 0.2s ease-out;

      @include bp(medium) {
        max-width: 60%;
        top: rem(276px);
      }
    }

    .rectangle-1 {
      position: absolute;
      top: 0;
      left: 25%;
      width: rem(87px);
      height: rem(436px);
      background: #a2a4c9;
      mix-blend-mode: multiply;
      transition: 0.5s var(--easing);
    }

    .rectangle-2 {
      position: absolute;
      top: 0;
      left: calc(25% + 87px);
      width: rem(86px);
      height: rem(343px);
      background-image: url('../images/info-cards-bg.jpg');
      background-size: cover;
      animation: bg-slide 100s linear infinite;
      transition: all 0.5s var(--easing);

      @keyframes bg-slide {
        from {
          background-position: 0% 0%;
        }

        50% {
          background-position: 100% 0%;
        }

        to {
          background-position: 0% 0%;
        }
      }
    }

    .rectangle-3 {
      display: none;
      position: absolute;
      top: 0;
      left: calc(55% + 222px + 230px);
      width: 231px;
      height: 344px;
      background: #191818;
      mix-blend-mode: multiply;
      opacity: 0.9;
    }

    @media screen and (min-width: 500px) {
      .rectangle-1 {
        left: 55%;
        width: rem(222px);
        height: 100%;
      }

      .rectangle-2 {
        left: calc(55% + 222px);
        width: rem(230px);
        height: rem(647px);
      }

      .rectangle-3 {
        display: block;
      }
    }

    &.-scrolling {
      @include bp(medium) {
        margin: 0 1.5rem 1.5rem;
        width: calc(100% - 3rem);

        .h2 {
          left: 2rem;
        }
      }
    }

    @media screen and (min-width: var(--container-width)) {
      &.-scrolling {
        margin: 0 3rem 3rem;
        width: calc(100% - 6rem);
      }
    }
  }

  main {
    .container {
      margin-top: rem(800px);

      @include bp(medium) {
        // margin-top: rem(940px);
        margin-top: calc(100vh + rem(120px));
      }
    }
  }
}

.about {
  &__hero {
    display: flex;
    flex-direction: column;

    @extend .margin-bottom-xl;

    @include bp(large) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .left {
        max-width: 55%;
        margin-right: rem(30px);
        padding-left: rem(140px);
      }
    }

    small {
      font-size: 1rem;
      opacity: 0.5;
      margin-top: rem(12px);
      display: block;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: var(--color-grey);
  }

  &__slider {
    @extend .margin-bottom-xl;

    padding: 0 1.5rem;

    @include bp(small) {
      margin-left: 1.5rem;
      padding: 0;
    }

    @include bp(medium) {
      margin-left: rem(160px);
    }
  }

  &__icon-boxes {
    @extend .margin-bottom-xl;

    .grid-col {
      --columns: 1;

      @include bp(small) {
        --columns: 3;
        --gap: 4rem;
      }
    }
  }

  &__blue {
    @extend .margin-bottom-xl;

    .blue-banner {
      @include bp(large) {
        padding: 6rem 9rem 6rem 11rem;
      }
    }
  }

  &__text {
    @extend .margin-bottom-xl;
  }
}

.team-title {
  @include bp(medium) {
    text-align: right;
  }
}

.team-members-container {
  --columns: 2;

  &.last-container {
    margin-bottom: rem(72px);
  }

  @include bp(small) {
    --columns: 4;

    &.last-container {
      margin-bottom: rem(88px);
    }
  }
}

.width-60 {
  width: 100%;

  @include bp(small) {
    width: 60%;
  }
}

.investment-icon-grid {
  --columns: 1;
  --gap: 1rem;

  @include bp(medium) {
    --columns: 3;
  }
}
