@use '../../assets/styles/mixins' as *;

$className: 'file';

.#{$className} {
  display: block;
  border: rem(1px) solid var(--color-primary);
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  margin-bottom: 1rem;

  @include bp(medium) {
    margin-bottom: 0;
  }

  &__container {
    min-height: 3rem;
    display: flex;
    align-items: center;
    border-left: rem(2px) solid var(--color-primary);
    padding-left: 1rem;
  }

  &__icon {
    flex-grow: 0;
    width: 1.5rem;
    aspect-ratio: 1;
    margin-right: 1rem;
    border-radius: 0.25rem;
    color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color var(--transition-duration) var(--easing);
  }

  &__title {
    flex-grow: 1;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--color-black);
    font-weight: 500;
    transition: color var(--transition-duration) var(--easing);
  }

  &__image {
    margin-left: 0.5rem;
    flex-grow: 0;

    img {
      max-height: 3rem;
      border-radius: var(--border-radius);
    }
  }

  &:hover {
    .#{$className} {
      &__title,
      &__icon {
        color: var(--color-primary);
      }
    }
  }
}
