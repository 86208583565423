@use '../../assets/styles/mixins' as *;

/**
 *
 * Button styles
 *
 */

$className: 'button';

/**
 *
 * Button config
 *
 */

$buttonPrimaryColor: var(--color-primary);
$buttonSecondaryColor: var(--color-primary-dark);
$buttonBorderRadius: var(--border-radius);
$buttonDisabledBackground: var(--color-primary);

// Box model
$buttonMargin: 1.5rem;
$buttonPadding: 0.5rem 1.5rem;

%button {
  appearance: none;
  cursor: pointer;
  margin: 0 auto $buttonMargin;
  text-align: center;
  backface-visibility: hidden;
  border-radius: $buttonBorderRadius;
  border: rem(1px) solid $buttonPrimaryColor;
  padding: $buttonPadding;
  background-color: $buttonPrimaryColor;
  color: var(--color-white);
  text-decoration: none;
  transition: all var(--transition-duration) var(--easing);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .icon {
    color: var(--color-white);
    width: 1rem;
    transition: all 0.25s ease;
    margin-right: 1rem;
  }

  &:hover {
    color: var(--color-white);
    background-color: $buttonSecondaryColor;
    border-color: $buttonSecondaryColor;
  }

  &[disabled] {
    background-color: $buttonDisabledBackground;
    border-color: $buttonDisabledBackground;
    cursor: default;
  }
}

.#{$className} {
  @extend %button;

  &--outline {
    background-color: var(--color-white);
    color: $buttonPrimaryColor;

    .icon {
      color: $buttonPrimaryColor;
    }

    &:hover {
      background-color: $buttonSecondaryColor;
      border-color: $buttonSecondaryColor;

      .icon {
        color: var(--color-white);
      }
    }
  }
}
