@use 'sass:color';
@use '../../assets/styles/mixins' as *;

.modal {
  &__overlay {
    display: none;
    position: fixed;
    inset: 0;
    z-index: 10;
    background: color.adjust(#111, $alpha: -0.4);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;


    &[aria-hidden='false'] {
      display: block;
    }

    &[aria-hidden='true'] {
      display: none;
    }

    @include bp(medium) {
      padding: 0 1rem;
    }
  }

  &__content {
    position: relative;
    background: var(--color-white);
    width: 100%;
    height: 100%;
    padding: 2.5rem 2rem 2rem;
    overflow-x: hidden;
    overflow-y: auto;

    @include bp(medium) {
      height: auto;
      max-width: rem(600px);
      max-height: 85vh;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      right: auto;
      bottom: auto;
    }

    picture,
    img {
      width: 100%;
    }

    & *:last-child {
      margin-bottom: 0;
    }

    &.-clean {
      padding: 0;
      max-width: rem(900px);
    }

    &.-team-member {
      max-width: rem(1358px);
      padding: rem(15px) rem(24px) 0;

      @include bp(small) {
        padding: rem(50px) rem(100px) 0;
      }

      @include bp(medium) {
        padding: rem(50px) rem(60px) 0;
      }

      @include bp(large) {
        padding: rem(50px) rem(120px) 0;
      }

      .modal__content-container {
        padding-top: rem(32px);
        display: flex;
        justify-content: space-between;
        background-image: url('../../assets/static/logo-grey.svg');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 100% auto;
        flex-direction: column-reverse;

        @include bp(medium) {
          padding-top: rem(60px);
          flex-direction: row;
          align-items: flex-end;
          height: 100%;
        }

        & > div {
          width: 100%;
          padding-bottom: rem(100px);

          @include bp(large) {
            padding-bottom: 5rem;
          }

          h2 {
            margin-bottom: 0.5rem;
          }

          .text-lead {
            margin-bottom: rem(40px);
          }

          & > p {
            @include bp(medium) {
              padding-right: 3rem;
            }
          }

          a.-back {
            display: block;
            margin-top: 3rem;

            @include bp(medium) {
              display: none;
            }
          }

          @include bp(medium) {
            align-self: flex-start;
          }
        }

        picture {
          border-bottom: 1px solid var(--color-black);
          margin: 0 auto 1.5rem;

          @include bp(small) {
            max-height: rem(640px);
          }

          @include bp(medium) {
            align-self: flex-end;
            border-bottom: none;
            margin-bottom: 0;
          }
        }

        img {
          width: auto;
          max-height: rem(640px);
          margin: 0 auto;
        }
      }
    }
  }

  &__close {
    position: absolute;
    width: 3rem;
    aspect-ratio: 1;
    top: 0.5rem;
    right: 0.5rem;
    color: var(--color-black);
    display: grid;
    place-items: center;
    cursor: pointer;

    @include bp(small) {
      top: rem(40px);
      right: rem(70px);
    }

    .icon {
      font-size: rem(34px);
    }
  }
}
