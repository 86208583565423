@use 'mixins' as *;

.logo {
  img {
    width: rem(250px);
  }
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  overflow: hidden;
  fill: currentColor;
  stroke: currentColor;
  pointer-events: none;
}

.hide-mobile {
  display: none !important;

  @include bp(medium) {
    display: inline-block !important;
  }
}

.hide-desktop {
  display: inline-block !important;

  @include bp(medium) {
    display: none !important;
  }
}

.hidden {
  display: none !important;
}
