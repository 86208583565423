@use '../../assets/styles/mixins' as *;

.identity-card {
  margin: 0 0 4rem;

  &__single {
    margin: 0 0 1.5rem;

    &-title {
      font-weight: 600;
    }
  }

  @include bp(medium) {
    margin: 0 0 5rem;

    &__single {
      display: flex;
      gap: 3rem;
      margin: 0.75rem;

      &-title {
        width: rem(300px);
      }

      &-desc {
        flex: 1;
      }
    }
  }
}
