@use '../../assets/styles/mixins' as *;

$className: 'icon-box';

.#{$className} {
  margin-bottom: 3rem;

  .icon {
    font-size: 3rem;
    stroke: transparent;
    margin-bottom: 1.5rem;
  }

  h3 {
    margin-bottom: 1.5rem;
  }

  h4 {
    margin-bottom: 1rem;
  }
}
