@use '../../assets/styles/mixins' as *;

$className: 'counter';

.counters-container {
  --gap: 30px;
  --columns: 1;

  @include bp(small) {
    --columns: 2;
  }

  @include bp(medium) {
    --columns: 4;
  }

  .#{$className} {
    text-align: center;
    margin-bottom: rem(56px);

    &__number {
      font-family: var(--font-heading);
      margin-bottom: 1rem;
      white-space: nowrap;

      &-number {
        display: inline-block;
        margin-bottom: 0;
        font-size: rem(96px);
        line-height: rem(72px);

        @include bp(medium) {
          font-size: rem(112px);
          line-height: rem(134px);
        }
      }

      &-prefix {
        font-size: rem(96px);
        line-height: rem(72px);

        @include bp(medium) {
          font-size: rem(112px);
          line-height: rem(134px);
        }
      }

      &-suffix {
        font-size: rem(40px);
        line-height: rem(72px);

        @include bp(medium) {
          font-size: rem(64px);
          line-height: rem(76px);
        }
      }
    }

    &__title {
      margin-bottom: 0;
      white-space: nowrap;
    }
  }

  &.--smaller {
    --columns: 2;
    --gap: 0;

    @include bp(small) {
      .grid-col {
        justify-items: center;
      }
    }

    .#{$className} {
      @include bp(medium) {
        min-width: 20rem;
      }

      &__number {
        &-number {
          font-size: rem(72px);
          line-height: rem(94px);

          @include bp(medium) {
            font-size: 6.5rem;
            line-height: 7rem;
          }
        }

        &-prefix {
          font-size: rem(72px);
          line-height: rem(94px);

          @include bp(medium) {
            font-size: 6.5rem;
            line-height: 7rem;
          }
        }

        &-suffix {
          font-size: rem(40px);
          line-height: rem(52px);

          @include bp(medium) {
            font-size: rem(64px);
            line-height: rem(76px);
          }
        }
      }
    }
  }
}

.counter-line {
  width: 100%;
  height: 1px;
  background-color: var(--color-grey);
}

.counter-desc {
  font-size: 1rem;
  opacity: 0.5;
  margin-top: rem(12px);
  display: block;
}
