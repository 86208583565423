/**
 *
 * Print Styles
 *
 */

@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    text-shadow: none !important;
    filter: none !important;
  }

  body *:not(#main):not(#main *) {
    display: none !important;
  }

  .news__single {
    margin-bottom: 3rem !important;
  }

  // body,
  // p,
  // ul,
  // table {
  //   font-size: 95% !important;
  // }

  a {
    &:visited {
      text-decoration: underline;
    }
  }

  thead {
    display: table-header-group;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 2cm 0.5cm;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3,
  h4 {
    page-break-after: avoid;
  }

  .hide-for-print {
    display: none !important;
  }
}
