@use 'vars';
@use 'mixins' as *;

body {
  font-family: var(--font-body);
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  position: relative;

  &::before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: absolute;
    background-image: url('../static/map.svg');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 100%;
    z-index: -1;
    opacity: 0.9;

    @include bp(medium) {
      opacity: 0.5;
    }
  }

  &.home {
    &::before {
      top: 80vh;
      height: calc(100% - 80vh);
    }
  }

  &:not(.home) {
    main {
      padding-top: rem(104px);
      @include bp(medium) {
        padding-top: rem(216px);
      }
    }
  }
}

main {
  flex-grow: 1;
}

.container {
  max-width: var(--container-width);
  margin: 0 auto;
  width: 100%;
}

.inner-container {
  max-width: var(--inner-container-width);
  margin: 0 auto;
  width: 100%;

  &.gutters {
    max-width: calc(var(--inner-container-width) + 3rem);
  }
}

.gutters {
  padding: 0 1.5rem;
}

.overlay {
  background: var(--color-black);
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;
}

.white-container {
  background: var(--color-white);
  color: var(--color-black);

  p:last-child {
    margin-bottom: 0;
  }

  @include bp(medium) {
    p {
      font-size: rem(20px);
    }
  }
}
