@use '../../../assets/styles/mixins' as *;

// Common styles
.form-item--checkbox input,
.form-item--radio input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  &:focus {
    ~ label::before {
      border-color: var(--color-primary);
    }
  }

  // Label
  ~ label {
    cursor: pointer;
    padding-left: 2.5rem;
    min-height: 1rem;
    line-height: 1.5rem;
    display: inline-block;
    position: relative;

    &::before,
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      display: block;
    }

    &::before {
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid var(--color-white);
      background: var(--color-white);
      color: var(--color-primary);
      transition: background-color var(--transition-duration) var(--easing);
    }

    &::after {
      width: 0;
      height: rem(12px);
      background-image: url('../images/check-white.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      left: rem(6px);
      top: rem(6px);
    }

    &:hover {
      &::before {
        background: var(--color-styleguide);
      }
    }
  }

  // Error
  .-has-error & {
    ~ label {
      &::before {
        background: var(--color-white);
        border-color: var(--color-accent);
      }

      &:hover {
        &::before {
          border-color: var(--color-accent);
        }
      }
    }
  }

  // Checked
  &:checked {
    ~ label {
      &::before {
        border-color: var(--color-white);
        background: var(--color-primary);
      }

      &::after {
        transition: width var(--transition-duration) var(--easing);
        width: rem(13px);
      }

      &:hover {
        &::before {
          border-color: var(--color-primary);
          background: var(--color-primary);
        }
      }
    }

    .-has-error & {
      ~ label {
        &::before {
          background: var(--color-accent);
          border-color: var(--color-accent);
        }
      }
    }
  }

  // Disabled
  &:disabled {
    cursor: not-allowed;

    ~ label {
      cursor: default;
      color: var(--color-primary);

      &::before {
        background-color: var(--color-primary);
        border-color: var(--color-white);
      }

      &::after {
        color: var(--color-primary);
      }

      &:hover {
        &::before {
          background-color: var(--color-primary);
          border-color: var(--color-primary);
        }
      }
    }
  }
}

// Checkbox specific styles
.form-item--checkbox input {
  ~ label {
    &::before {
      border-radius: var(--border-radius);
    }
  }
}

// Radio specific styles
.form-item--radio input {
  ~ label {
    &::before {
      border-radius: 50%;
    }
  }
}

.form-item--checkbox.-has-error {
  .form-item__error {
    padding-left: 2.5rem;
  }
}

// Group specific styles

.form-item-group {
  .form-item {
    margin-bottom: 1rem;

    &__error {
      padding-left: 2.5rem;
    }
  }

  &.-inline > div {
    display: flex;

    .form-item {
      margin-right: 2rem;

      &.-has-error {
        margin-bottom: 0;
      }
    }
  }

  &.-has-error {
    .form-item--checkbox input,
    .form-item--radio input {
      &:checked {
        ~ label {
          &::before {
            background: var(--color-primary) !important;
            border-color: var(--color-primary) !important;
          }
        }
      }
    }
  }
}

.radio-group-label {
  display: block;
  margin-bottom: 0.5rem;
}
