@use '../../assets/styles/mixins' as *;

$className: 'breadcrumbs';

.#{$className} {
  padding-bottom: 1.3rem;
  font-size: 1rem;
  line-height: 1rem;
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 1.5rem;

  .icon {
    display: inline-block;
    margin: 0 0.7rem;
    color: var(--color-black);
    font-size: 0.8rem;
  }

  &__link {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &__current {
    color: var(--color-primary);
  }
}
