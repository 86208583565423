@use '../../assets/styles/mixins' as *;

$className: 'swiper';

.#{$className} {
  &-wrapper {
    display: flex;
  }

  &-button-next,
  &-button-prev {
    top: 50%;
    background: rgb(25 24 24 / 0.5);
    width: 3rem;
    height: 3rem;
    padding: 0;
    border-radius: 50px;
    border: rem(1px) solid var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--color-black);
    transition: background-color var(--transition-duration) var(--easing);

    &::after {
      content: none;
    }

    &:hover {
      background-color: var(--color-primary);
      color: var(--color-white);
    }

    .icon {
      font-size: 1rem;
      color: var(--color-white);
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  &-button-next {
    right: 1rem;
  }

  &-button-prev {
    left: 1rem;

    .icon {
      transform: rotate(180deg);
    }
  }
}
