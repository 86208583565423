/**
 *
 * Typography
 *
 */

@use 'vars';
@use 'mixins' as *;

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.heading-font {
  font-family: var(--font-heading);
}

h1,
.h1 {
  @extend .heading-font;
  @extend .regular;

  font-size: rem(56px);
  line-height: rem(64px);
  margin-bottom: 1em;

  @include bp(medium) {
    font-size: rem(96px);
    line-height: rem(72px);
  }
}

h2,
.h2 {
  @extend .heading-font;
  @extend .bold;

  font-size: rem(40px);
  line-height: rem(48px);
  margin-bottom: 1em;

  @include bp(medium) {
    font-size: rem(54px);
    line-height: rem(64px);
  }
}

h3,
.h3 {
  @extend .heading-font;
  @extend .regular;

  font-size: rem(38px);
  line-height: rem(40px);
  margin-bottom: 1em;

  @include bp(medium) {
    font-size: rem(46px);
    line-height: rem(48px);
  }
}

h4,
.h4 {
  @extend .heading-font;
  @extend .regular;

  font-size: rem(26px);
  line-height: rem(24px);
  margin-bottom: 1em;

  @include bp(medium) {
    font-size: rem(38px);
    line-height: rem(40px);
  }
}

h5,
.h5 {
  @extend .medium;

  font-size: rem(19px);
  line-height: rem(24px);
  margin-bottom: 1em;

  @include bp(medium) {
    font-size: rem(24px);
    line-height: rem(32px);
  }
}

.text-lead {
  @extend .regular;

  font-size: rem(20px);
  line-height: rem(32px);

  @include bp(medium) {
    font-size: rem(24px);
    line-height: rem(48px);
  }
}

p {
  @extend .regular;

  font-size: rem(18px);
  line-height: rem(32px);
  margin-bottom: 1.5em;
}

.text-small,
small {
  @extend .regular;

  font-size: rem(16px);
  line-height: rem(16px);

  @include bp(medium) {
    font-size: rem(18px);
    line-height: rem(24px);
  }
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

ul {
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 1em;

    &::marker {
      color: var(--color-primary);
    }

    ul {
      li {
        list-style-type: disc;
      }
    }
  }
}
