@use '../../assets/styles/mixins' as *;

.blue-banner {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 3rem 1.5rem 3.5rem;

  p:last-child {
    margin: 0;
  }

  &--image {
    .blue-banner {
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
      }

      &__text {
        p:last-child {
          margin: 0;
        }
      }
    }

    picture {
      margin-top: 3rem;
      transform: rotate(90deg);

      img {
        max-width: rem(200px);
      }
    }
  }

  @include bp(medium) {
    padding: 6rem 1.5rem;

    &__wrap {
      max-width: rem(1120px);
      margin: 0 auto;
    }

    &--image {
      padding: 4.5rem 1.5rem;
      margin-bottom: 3rem;

      .blue-banner {
        &__wrap {
          flex-direction: row;
        }

        &__text {
          order: 2;
          max-width: 50%;
        }
      }

      picture {
        transform: rotate(0deg);
        order: 1;
        margin-top: 0;
      }
    }
  }
}
