@use '../../assets/styles/mixins' as *;

$className: 'footer';

.#{$className} {
  background-color: var(--color-black);

  .container {
    background-image: url('../../assets/static/footer-texture.jpg');
    background-repeat: no-repeat;
    background-position: right rem(620px);
    background-size: 100% auto;
    padding: 2.5rem 1rem 18.25rem;

    @include bp(medium) {
      background-position: right bottom;
      background-size: auto 100%;
      padding: rem(64px) rem(54px) rem(56px);
    }
  }

  &__content {
    @include bp(medium) {
      width: 67%;
    }
  }

  &__up {
    @include bp(medium) {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  &__logo {
    img {
      min-width: rem(229px);
    }
  }

  &__extra-logo {
    max-width: 250px;
    display: block;
    margin-bottom: 3rem;
  }

  &__bottom {
    display: flex;
    flex-direction: column;

    @include bp(medium) {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  &__links {
    margin-top: rem(120px);
    padding-right: rem(120px);
    margin-bottom: rem(64px);
    order: 1;

    @include bp(medium) {
      order: 2;
      padding-left: 4rem;
      margin-bottom: 0;
      margin-top: 4rem;
    }

    li {
      margin-bottom: rem(18px);

      &::marker {
        content: none;
      }

      a {
        color: var(--color-white);
        font-family: var(--font-heading);
        font-size: rem(24px);
        line-height: rem(24px);

        &::before {
          background-color: var(--color-white);
        }
      }
    }
  }

  &__left {
    order: 2;

    @include bp(medium) {
      order: 1;
    }
  }

  &__contact {
    a {
      display: inline-block;
      color: var(--color-white);
      margin-bottom: 1rem;

      &::before {
        background-color: var(--color-white);
      }
    }

    .icon {
      display: inline-block;
      font-size: 1.2rem;
      color: var(--color-white);
      margin-right: 1rem;
    }
  }
}
