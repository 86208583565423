@use 'mixins' as *;

a {
  color: var(--color-black);
  text-decoration: none;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: rem(1px);
    bottom: 0;
    left: 0;
    background-color: var(--color-black);
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.3s ease;
  }

  &:hover,
  &:focus {
    &::before {
      transform: scaleX(1);
    }
  }

  &.no-underline {
    &::before {
      display: none;
    }

    &:hover {
      &::before {
        display: none;
      }
    }
  }

  &.more {
    color: var(--color-accent);
    font-weight: 600;
    font-size: rem(18px);
    line-height: 2rem;
    display: inline-flex;
    align-items: center;
    padding-right: 1rem;
    position: relative;
    text-decoration: none;

    .icon {
      position: absolute;
      right: -2.5rem;
      top: 50%;
      transform: translate(0, -50%);
      transition: all 0.25s ease;
      width: 2.5rem;
    }

    &::before {
      display: none;
    }

    &:hover {
      color: var(--color-accent);

      .icon {
        right: -3rem;
      }

      &::before {
        display: none;
      }
    }
  }

  &.-back {
    padding-left: 3.5rem;
    padding-right: 0;

    .icon {
      position: absolute;
      left: 0;
      right: unset;
      top: 50%;
      transform: translate(0, -50%);
      transition: all 0.25s ease;
      width: 2.5rem;
    }

    &:hover {
      color: var(--color-accent);

      .icon {
        left: -0.5rem;
      }

      &::before {
        display: none;
      }
    }
  }
}
