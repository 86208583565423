@use '../../assets/styles/mixins' as *;

$className: 'timeline';

.#{$className} {
  position: relative;
  margin-top: rem(56px);
  padding-bottom: rem(96px);

  @include bp(small) {
    margin-top: rem(85px);
  }

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: rem(2px);
    top: 1rem;
    left: rem(4px);
    background: var(--color-black);
    z-index: 1;

    @include bp(small) {
      top: 0;
      left: 50%;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    overflow: hidden;

    &-item {
      list-style: none;
      position: relative;
      padding: 0 rem(28px);
      width: 100%;
      text-align: left;

      @include bp(small) {
        width: 50%;
        padding: 0 rem(70px);
      }

      &::before {
        content: '';
        position: absolute;
        width: 0.5rem;
        aspect-ratio: 1;
        background: var(--color-black);
        z-index: 1;
        transform: rotate(-45deg);
        top: rem(1px);
      }

      &.--left {
        @include bp(medium) {
          padding: 0 4.375rem 0 0;
        }
      }

      &.--right {
        @include bp(medium) {
          padding: 0 0 0 4.375rem;
        }
      }

      &:nth-child(odd) {
        float: right;
        text-align: left;
        clear: both;

        &::before {
          top: 1rem;
          left: rem(1px);

          @include bp(small) {
            top: rem(1px);
          }
        }

        @include bp(small) {
          float: left;
          text-align: right;
          clear: both;

          &::before {
            right: rem(-5px);
            left: unset;
          }
        }
      }

      &:nth-child(even) {
        float: right;
        text-align: left;
        clear: both;

        &::before {
          top: 1rem;
          left: rem(1px);

          @include bp(small) {
            left: rem(-3px);
            top: unset;
          }
        }
      }

      ul {
        padding-left: rem(28px);
      }

      & > div {
        text-align: left;

        h2,
        p {
          margin-bottom: 1rem;
          @include bp(small) {
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }

  &.--centered {
    padding-bottom: rem(84px);

    & > h2 {
      text-align: center;
      margin-bottom: rem(30px);
      margin-top: 1.5rem;
    }

    &::before {
      display: none;
    }

    .#{$className} {
      &__list {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          height: 100%;
          width: rem(2px);
          top: 0;
          left: 50%;
          background: var(--color-black);
          z-index: 1;
        }

        &-item {
          width: 50%;

          &.--left {
            float: left;
            text-align: right;
            clear: both;

            &::before {
              right: rem(-5px);
              left: unset;
              top: rem(1px);
            }

            & > div {
              text-align: right;
            }
          }

          &.--right {
            float: right;
            text-align: left;
            clear: both;

            &::before {
              left: rem(-3px);
              top: rem(1px);
            }
          }

          & > div {
            h4,
            p {
              margin-bottom: 0.25rem;
            }

            p {
              line-height: 1.5rem;
            }

            @media (max-width: 360px) {
              h4 {
                font-size: rem(20px);
              }

              p {
                font-size: 1rem;
              }
            }

            @include bp(medium) {
              h4 {
                font-size: 2rem;
              }
            }
          }
        }
      }
    }

    small {
      display: block;
      max-width: rem(315px);
      text-align: center;
      margin: 0 auto;
    }
  }

  &-header {
    width: 100%;
    display: flex;

    @include bp(medium) {
      align-items: center;
    }

    h5 {
      width: 50%;
      background-color: var(--color-primary);
      color: var(--color-white);
      padding: 1rem 0.5rem 1rem 1rem;
      margin: 1rem 0 0;
      display: flex;
      align-items: center;

      &:first-child {
        width: calc(50% - 1px);
        border-right: 1px solid var(--color-white);
      }

      @include bp(medium) {
        padding: 1rem rem(30px);
      }
    }
  }
}
