@use '../../assets/styles/mixins' as *;

.info-card {
  background: var(--color-black);
  color: var(--color-white);
  padding: 1rem 1rem 2rem;
  border-bottom: 1px solid var(--color-white);
  cursor: pointer;

  &-wrap {
    position: relative;
  }

  h3,
  h4,
  p {
    margin: 0 0 1rem;
  }

  &__content {
    padding-top: 0.5rem;
    display: none;
  }

  &__close {
    display: none;
  }

  &__footer {
    display: flex;
    flex-shrink: 0;
  }

  button {
    border-radius: 50%;
    background: var(--color-black);
    border: 1px solid var(--color-white);
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
    cursor: pointer;
    transition: all 0.25s ease;
    display: flex;
    flex-shrink: 0;
    padding: 0;

    .icon {
      color: var(--color-white);
      font-size: 1rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.25s ease;
    }

    .info-card__close {
      .icon {
        font-size: 0.75rem;
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      button {
        background: var(--color-white);

        .icon {
          color: var(--color-black);
        }
      }
    }
  }

  &.active {
    background-image: url('../images/info-card-bg.jpg') !important;
    background-size: cover;
    animation: bg-slide 180s linear infinite;

    @keyframes bg-slide {
      from {
        background-position: 0% 0%;
      }

      50% {
        background-position: 100% 0%;
      }

      to {
        background-position: 0% 0%;
      }
    }

    .info-card {
      &__content {
        display: block;
      }

      &__close {
        display: block;
      }

      &__open {
        display: none;
      }
    }
  }

  @include bp(medium) {
    height: 375px;
    padding: 2rem;
    width: 33.33%;
    position: absolute;
    border-left: 1px solid var(--color-white);
    transition: all 0.25s ease;
    transition-property: height, top;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: contain;
    background-repeat: no-repeat;

    button {
      width: 2rem;
      height: 2rem;

      .icon {
        font-size: 1.75rem;
      }

      .info-card__close {
        .icon {
          font-size: 1rem;
        }
      }
    }

    &-wrap {
      height: 750px;
      transition: height 0.25s ease;

      &.expanded {
        height: 1125px;
      }
    }

    &__content {
      padding-top: 3rem;
    }

    &.active {
      height: 750px;
    }

    &:nth-child(1) {
      top: 0;
      left: 0;
      background-image: url('../images/1-zgorajlevo.jpg');

      &.active {
        & + .info-card {
          & + .info-card {
            & + .info-card {
              top: 750px;
            }
          }
        }
      }
    }

    &:nth-child(2) {
      top: 0;
      left: 33.33%;
      background-image: url('../images/2-zgorajsredinsko.jpg');

      &.active {
        & + .info-card {
          & + .info-card {
            & + .info-card {
              top: 750px;
            }
          }
        }
      }
    }

    &:nth-child(3) {
      top: 0;
      right: 0;
      background-image: url('../images/3-zgorajdesno.jpg');

      &.active {
        & + .info-card {
          & + .info-card {
            & + .info-card {
              top: 750px;
            }
          }
        }
      }
    }

    &:nth-child(4) {
      top: 375px;
      left: 0;
      background-image: url('../images/4-spodajlevo.jpg');
    }

    &:nth-child(5) {
      top: 375px;
      left: 33.33%;
      background-image: url('../images/5-spodajsredinsko.jpg');
    }

    &:nth-child(6) {
      top: 375px;
      right: 0;
      background-image: url('../images/6-spodajdesno.jpg');
    }
  }
}
