@use '../../assets/styles/mixins' as *;

$className: 'news';

.#{$className}__container {
  .#{$className}__year {
    border-bottom: 1px solid var(--color-black);
    margin: 3.5rem 0 2rem;

    h2 {
      margin: 0 0 1rem;
    }
  }

  .#{$className}__single {
    h3 {
      margin: 0 0 1.5rem;
    }

    p {
      margin: 0 0 2rem;
    }

    a {
      font-weight: 600;
      text-decoration: underline;

      &:hover {
        color: var(--color-accent);
      }
    }
  }

  @include bp(medium) {
    display: grid;
    grid-template-columns: 9rem 1fr;
    gap: 6rem;
    border-top: 1px solid var(--color-black);
    padding: 3rem 0 0;
    max-width: rem(890px);

    .#{$className}__year {
      border-bottom: 0;
      width: 9rem;
      margin: 0;
    }

    .#{$className}__single {
      flex: 1;
      margin-bottom: 3rem;
    }
  }
}
