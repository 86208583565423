@forward 'input/input';
@forward 'checkbox-radio/checkbox-radio';
@forward 'textarea/textarea';
@forward 'select/select';
@forward 'date-picker/date-picker';
@use '../../assets/styles/mixins' as *;

$className: 'form-item';

.#{$className} {
  margin-bottom: 2rem;

  label {
    display: block;
    color: var(--color-black);
    font-weight: 500;

    span {
      color: var(--color-primary);
      display: inline-block;
      margin-left: 0.25rem;
      font-weight: 500;
    }
  }

  &.-has-icon {
    div {
      position: relative;
    }
  }

  &.-has-error {
    label {
      &::before {
        border-color: var(--color-accent);
      }

      &:hover {
        &::before {
          border-color: var(--color-accent);
          background-color: var(--color-white);
        }
      }
    }

    .#{$className}__icon {
      .icon {
        color: var(--color-accent);
      }
    }
  }

  &__icon {
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 1px;
    min-width: 1rem;
    background: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.875rem;
    border-radius: 0.5rem;
    height: calc(100% - 4px);
    cursor: pointer;

    .icon {
      font-size: 1rem;
      color: var(--color-black) !important;
    }
  }

  &__note,
  &__error {
    display: block;
    padding: 0.5rem 0 0 0.5rem;
    font-size: 0.875rem;
    line-height: 1rem;

    &.--with-padding {
      padding-left: 2.5rem;
    }
  }

  &__note {
    color: var(--color-primary);
  }

  &__error {
    color: var(--color-accent);
  }

  &--textarea {
    label {
      margin-bottom: 1rem;
    }
  }

  &--select {
    margin-bottom: 0;
  }
}

@include bp(small) {
  .input-group {
    display: flex;
    gap: rem(14px);

    .group-input-small {
      width: rem(100px);
    }

    .group-input-big {
      flex: 1;
    }
  }
}

// Clear placeholders on focus
[placeholder]::-webkit-input-placeholder {
  transition: opacity var(--transition-duration) var(--easing);
  opacity: 1;
}

[placeholder]:focus::-webkit-input-placeholder {
  opacity: 0;
}
