@use 'mixins' as *;

$className: 'styleguide';

body.#{$className} {
  // stylguide styles
  main {
    padding-bottom: 2rem;
  }

  .#{$className} {
    &-inner-container {
      padding: 0 1rem;
    }

    &-title {
      color: var(--color-styleguide);
    }

    &-icons {
      --col-width: 10rem;

      &__icon {
        padding: 1rem;
        border: rem(1px) solid var(--color-primary);
        border-radius: var(--border-radius);
        text-align: center;

        p {
          margin: 1rem 0 0;
        }

        .icon {
          font-size: 3rem;
          color: firebrick;
        }
      }
    }

    &-black {
      background-color: var(--color-black);
      padding-bottom: 1rem;
    }

    &-colors {
      --col-width: 10rem;

      .color {
        padding: 1rem;
        border-radius: var(--border-radius);
        text-align: center;

        p {
          margin-bottom: 0;

          &:last-child {
            text-transform: uppercase;
          }
        }

        &.-white-text {
          color: var(--color-white);
        }

        &.-with-border {
          border: rem(1px) solid rgba(0 0 0 / 0.08);
        }
      }
    }

    &-slider-container {
      max-width: rem(1280px);
    }

    &-icon-box-container {
      --columns: 1;

      @include bp(medium) {
        --columns: 3;
        --gap: rem(140px);
      }
    }

    &-forms {
      --columns: 1;
      @extend .margin-bottom-md;

      @include bp(medium) {
        --columns: 2;
        --gap: 2rem;
      }
    }

    &-height {
      max-height: 20rem;
    }

    &-tooltips {
      --col-width: 8rem;

      .square {
        border-radius: var(--border-radius);
        padding: 1rem;
        font-size: 600;
        color: var(--color-white);
        text-align: center;

        p {
          margin-bottom: 0;
        }

        &.-blue {
          background-color: var(--color-primary);
        }

        &.-red {
          background-color: var(--color-secondary);
        }

        &.-green {
          background-color: var(--color-accent);
        }

        &.-orange {
          background-color: var(--color-black);
        }
      }
    }

    &-flex {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
