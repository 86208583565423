@use '../../assets/styles/mixins' as *;

.contact-card {
  display: flex;
  flex-direction: column;

  &__content {
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 1.5rem 2.5rem 2rem;
    flex: 1;

    h3,
    p {
      margin: 0 0 1.5rem;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin: 0 0 0.5rem;

      .icon {
        color: var(--color-white);
      }

      a {
        color: var(--color-white);
        display: flex;
        align-items: center;
        gap: 1.5rem;
      }
    }
  }

  @include bp(medium) {
    &__content {
      padding: 3rem;

      p {
        margin: 0 0 3rem;
      }

      li {
        margin: 0 0 1rem;
      }
    }
  }
}

.contact-card-grid {
  margin-bottom: 3rem;

  @include bp(xsmall) {
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

    // & > div {
    //   width: 50%;
    // }
  }

  @include bp(medium) {
    margin-bottom: 5rem;
    grid-template-columns: 1fr 1fr;
  }

  @include bp(large) {
    display: flex;
  }
}

.contact-info {
  --gap: 1rem;
  --columns: 1;

  margin-bottom: 4rem;

  h3 {
    margin: 0 0 1.5rem;
  }

  p {
    margin: 0 0 1rem;
    display: flex;
    flex-direction: column;
  }

  @include bp(medium) {
    --gap: 4rem;
    --columns: 2;

    margin-bottom: 5rem;

    h3 {
      margin: 0 0 2rem;
    }

    p {
      margin: 0;
      flex-direction: row;
      gap: 1rem;
    }

    .flexed {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
