@use '../../assets/styles/mixins' as *;

.skip-link {
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  transform: translateY(-100%);
  padding: 0.5rem 1rem;
  background: var(--color-white);
  border: 1px solid var(--color-black);
  border-top: none;
  transition: background-color 0.15s ease-in, transform 0.15s ease-in;
  outline: none;

  &::before {
    content: none;
  }

  @media (hover: hover) {
    &:hover {
      background: var(--color-black);
      color: var(--color-white);
    }
  }

  &:focus {
    transform: translateY(0);
  }
}
