@use '../../assets/styles/mixins' as *;

$className: 'header';

.#{$className} {
  padding: 0 1rem;
  height: rem(71px);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;

  @include bp(medium) {
    padding: 0 1rem 7rem;
    height: rem(104px);
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: margin-top 0.2s var(--easing-menu);

    .logo {
      display: block;
      background-image: url('../../assets/static/logo.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      transition: width 0.2s ease-out, height 0.2s ease-out, margin-left 0.2s ease-out;
      width: rem(122px);
      height: rem(48px);
      margin-top: 0.75rem;

      @include bp(medium) {
        width: rem(197px);
        height: rem(78px);
        margin-top: rem(24px);
      }

      &::before {
        display: none;
      }
    }

    .hamburger {
      position: absolute;
      top: 0;
      right: 0;
      width: rem(71px);
      height: rem(71px);
      background-color: var(--color-black);
      cursor: pointer;

      div {
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: rem(34px);
        height: rem(10px);
      }

      span {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--color-white);
        transition: all 0.2s var(--easing-menu);
        transform-origin: center;

        &:first-of-type {
          top: 0;
        }

        &:last-of-type {
          bottom: 0;
        }
      }

      &.active {
        span {
          &:first-of-type {
            transform: rotate(45deg);
            top: rem(5px);
          }

          &:last-of-type {
            transform: rotate(-45deg);
            bottom: rem(2.5px);
          }
        }
      }

      &.active:hover span:first-of-type,
      &.active:hover span:last-of-type {
        width: rem(34px);
      }

      @include bp(medium) {
        display: none;
      }
    }

    nav {
      height: rem(100px);
      display: none;

      @include bp(medium) {
        display: block;
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 0.5rem;
        transition: gap 0.2s ease-out;

        @include bp(medium) {
          gap: 1.5rem;
        }

        @include bp(large) {
          gap: 3rem;
        }

        li {
          list-style: none;
          margin-bottom: 0;

          a {
            display: block;
            height: rem(100px);
            position: relative;
            padding: 0 0.5rem;
            font-family: var(--font-heading);
            font-size: 1.5rem;
            line-height: 1.5rem;
            transition: color 0.2s ease-out;

            @include bp(medium) {
              padding: 0 rem(25px);
            }

            @include bp(large) {
              padding: 0 rem(40px);
            }

            span {
              display: inline-block;
              padding-top: rem(45px);
              position: relative;

              &::before {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: rem(1px);
                bottom: 0;
                left: 0;
                background-color: var(--color-black);
                transform: scaleX(0);
                transform-origin: top left;
                transition: transform 0.3s ease;
              }
            }

            &:hover,
            &:focus {
              span {
                &::before {
                  transform: scaleX(1);
                }
              }
            }

            &::before {
              content: '';
              position: absolute;
              display: block;
              width: 100%;
              height: 7rem;
              top: rem(-1px);
              left: 0;
              background-color: var(--color-black);
              transform: scaleY(0) translateZ(0);
              transform-origin: top center;
              transition: transform 0.4s ease;
              backface-visibility: hidden;
            }

            &.current {
              color: var(--color-white);

              &::before {
                transform: scaleY(1) translateZ(0);
              }
            }
          }
        }
      }
    }
  }

  &.--black {
    position: fixed;
    background: transparent;

    .container {
      margin-top: 1rem;

      .logo {
        background-image: url('../../assets/static/logo-white.svg');
        width: rem(203px);
        height: rem(80px);
        margin-top: rem(19px);

        &.smaller {
          width: rem(197px);
          height: rem(78px);
          margin-top: rem(26px);
        }
      }

      nav {
        margin-right: 1rem;

        ul {
          li {
            a {
              color: var(--color-white);

              span {
                &::before {
                  content: '';
                  position: absolute;
                  display: block;
                  width: 100%;
                  height: rem(1px);
                  bottom: 0;
                  left: 0;
                  background-color: var(--color-white);
                  transform: scaleX(0);
                  transform-origin: top left;
                  transition: transform 0.3s ease;
                }
              }

              &::before {
                display: none;
              }

              &:hover,
              &:focus {
                span {
                  &::before {
                    transform: scaleX(1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.mobile {
    padding: 0 1rem;
    height: 100vh;
    background-color: var(--color-black);
    background-image: url('../../assets/static/map-menu.svg');
    background-size: 100% auto;

    .container {
      height: 100%;
      flex-direction: column;
      align-items: unset;
      justify-content: unset;

      .logo {
        background-image: url('../../assets/static/logo-white.svg');
        width: rem(122px);
        height: rem(48px);
        margin-top: 1rem;
      }

      nav {
        display: block;
        height: auto;
        margin-top: rem(118px);

        ul {
          flex-direction: column;
          align-items: unset;
          justify-content: unset;

          li {
            margin-bottom: 2rem;

            a {
              color: var(--color-white);
              height: auto;
              font-size: rem(38px);
              line-height: rem(40px);
              padding-left: 2rem;

              span {
                padding-top: 0;
              }

              &.current {
                &::before {
                  content: '';
                  position: absolute;
                  width: 0.5rem;
                  height: 0.5rem;
                  background: var(--color-white);
                  transform: rotate(-45deg);
                  top: calc(50% - 0.25rem);
                }
              }
            }
          }
        }
      }
    }
  }

  &.-scrolling {
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-down);

    .container {
      .logo {
        margin-top: 0.75rem;
        width: rem(122px);
        height: rem(48px);
      }
    }

    &.--black {
      .container {
        margin-top: 0;

        .logo {
          background-image: url('../../assets/static/logo.svg');
          margin-top: 0.75rem;
          width: rem(122px);
          height: rem(48px);
        }

        nav ul li {
          a {
            @include bp(medium) {
              color: var(--color-black);

              span::before {
                background-color: var(--color-black);
              }
            }
          }
        }
      }

      &.mobile {
        .container .logo {
          background-image: url('../../assets/static/logo-white.svg');
        }
      }
    }

    &.mobile {
      background-color: var(--color-black);
      box-shadow: none;
    }
  }
}
