@use 'mixins' as m;

:root {
  // colors
  --color-styleguide: #aaa;
  --color-white: #fff;
  --color-black: #191818;
  --color-primary: #0b0d33;
  --color-secondary: #0d2820;
  --color-accent: #42469a;
  --color-grey: #f4f5f6;

  // shadows
  --box-shadow: 0.25rem 0.25rem 0.75rem 0 rgb(0 0 0 / 0.15);
  --box-shadow-down: 0.25rem 0 0.75rem 0 rgb(0 0 0 / 0.1);

  // general
  --container-width: 1440px;
  --inner-container-width: 1120px;
  --font-body: 'Libre Franklin', sans-serif;
  --font-heading: 'Publico Text', sans-serif;

  // transitions
  --easing: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --easing-menu: cubic-bezier(0.1, 0.82, 0.76, 0.965);
  --transition-duration: 0.2s;

  // spacings
  --spacing-tiny: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2rem;
  --spacing-xl: 2.5rem;

  @include m.bp(medium) {
    --spacing-tiny: 1rem;
    --spacing-sm: 2rem;
    --spacing-md: 3rem;
    --spacing-lg: 4rem;
    --spacing-xl: 5rem;
  }

  --border-radius: 0.5rem;
}
