@use '../../assets/styles/mixins' as *;

.team-member {
  max-width: rem(315px);
  cursor: pointer;

  &:hover {
    .team-member {
      &__button {
        background-color: var(--color-black);
        color: var(--color-white);

        &::before {
          display: none;
        }
      }
    }
  }

  &__image {
    cursor: pointer;
    border-bottom: 1px solid var(--color-black);
  }

  &__button {
    width: 2rem;
    height: 2rem;
    aspect-ratio: 1;
    border: 1px solid var(--color-black);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s var(--easing), background-color 0.2s var(--easing);

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__name {
    margin: 1.5rem 0 0;
  }

  &__title {
    margin: 0 0 rem(12px);
  }
}
